<template>
  <b-row>
    <b-col lg="3" sm="6">
      <customers-counts-analyse icon="UsersIcon" color="info" />
    </b-col>
    <b-col lg="3" sm="6">
      <documents-counts-analyse icon="FileIcon" color="success" />
    </b-col>
    <b-col lg="3" sm="6">
      <events-counts-analyse icon="CalendarIcon" color="danger" />
    </b-col>
    <b-col lg="3" sm="6">
      <templates-counts-analyse icon="EditIcon" color="primary" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import DocumentsCountsAnalyse from '@/views/components/analyse/DocumentsCountsAnalyse.vue'
import CustomersCountsAnalyse from '@/views/components/analyse/CustomersCountsAnalyse.vue'
import EventsCountsAnalyse from '@/views/components/analyse/EventsCountsAnalyse.vue'
import TemplatesCountsAnalyse from '@/views/components/analyse/TemplatesCountsAnalyse.vue'

export default {
  components: {
    TemplatesCountsAnalyse,
    EventsCountsAnalyse,
    CustomersCountsAnalyse,
    DocumentsCountsAnalyse,
    BRow,
    BCol,
  },
}
</script>
