<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="info in list"
        :key="info.name"
        class="px-1 py-1"
        :action="false"
      >
        <span class="">{{ [info.name, '-', info.text].join(' ') }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const list = [
      {
        name: 'Контрагент',
        text: 'Заказчик, клиент, лицо данные которого участвуют в формировании документов. Могут быть физическим или юридическим лицом',
      },
      {
        name: 'Документы',
        text:
          'Список сформированных документов и их данные. ' +
          'Документ с типом "Договор" является главным и все остальные типы документов могут быть к нему привязаны и использовать его данные',
      },
      {
        name: 'Шаблоны',
        text:
          'Шаблоны документов, при помощи которых формируются документы. ' +
          'Могут использовать собственные изменяемые поля, брать данные из контрагента, типа документа и привязанного договора (если тип документа не договор)',
      },
      {
        name: 'Типы документов',
        text: 'Типы документов, такие как договор, счет, акт сдачи и т. д. Здесь настраиваются изменяемые поля для каждого типа документа',
      },
      {
        name: 'Журнал выездов',
        text: 'Календарь назначенных выездов по договору',
      },
      {
        name: 'Роли',
        text: 'Настройка ролей и прав для пользователей группы. Доступно только для основного аккаунта',
      },
    ]
    return {
      list,
    }
  },
}
</script>
