<template>
  <b-card no-body>
    <Loader v-if="isLoading" class="m-auto" />
    <template v-else>
      <b-card-body class="pb-0 d-flex justify-content-between">
        <div class="truncate">
          <span>{{ 'Шаблонов' }}</span>
          <h2 class="font-weight-bolder">
            {{ kFormatter(totalData) }}
          </h2>
        </div>
        <b-avatar :variant="`light-${color}`" size="44">
          <feather-icon size="20" :icon="icon" />
        </b-avatar>
      </b-card-body>

      <vue-apex-charts
        :key="totalData"
        type="area"
        height="100"
        width="100%"
        :options="chartOptionsComputed"
        :series="chartData"
      />
    </template>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from './chartOptions'
import { useAnalyseQuery } from '@/store/analyse'
import { computed, ref, toRefs } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter'

export default {
  methods: { kFormatter },
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { chartOptions, color } = toRefs(props)
    const chartData = ref([
      {
        name: 'Шаблонов',
        data: [],
      },
    ])

    const totalData = computed(() =>
      chartData.value[0].data.reduce((acc, cur) => acc + cur, 0)
    )

    const chartOptionsComputed = computed(() => {
      if (chartOptions.value === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[color.value]
        return options
      }
      return chartOptions.value
    })

    const { isLoading } = useAnalyseQuery.counts({
      type: ref('templates'),
      options: {
        onSuccess(data) {
          if (data.success) {
            chartData.value[0].data = data.analyse.counts ?? []
          }
        },
      },
    })

    return { chartData, totalData, isLoading, chartOptionsComputed }
  },
}
</script>
