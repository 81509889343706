<template>
  <div>
    <b-row>
      <b-col cols="12">
        <Analytics />
      </b-col>
      <b-col cols="12">
        <MainInfo />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import MainInfo from './MainInfo.vue'
import Analytics from '../../components/analyse/Analytics.vue'

export default {
  components: {
    BRow,
    BCol,
    MainInfo,
    Analytics,
  },
}
</script>
